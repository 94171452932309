<template>
  <default-layout>
    <ui-container
      fluid
      no-gutters-x
      spacing="none"
      class="flex-1 flex sm:gap-5 lg:gap-7"
    >
      <div
        ref="contentWrap"
        class="w-full flex-1 max-h-full overflow-y-auto flex px-2.5 -mx-2.5"
      >
        <slot />
      </div>
      <common-rooms-tab-container
        v-if="showSidebar"
        class="w-[445px] shrink-0 lg:block hidden"
      />
    </ui-container>
  </default-layout>
</template>

<script setup lang="ts">
  import DefaultLayout from '@/layouts/default.vue';
  const route = useRoute();

  const showSidebar = computed(() => !route.meta.hideSidebar);
  const { contentWrap } = makeLayoutWrap();
</script>
